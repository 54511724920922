const AddressData = [
  {
    id: "1",
    address:
      "MobileBitz Unit 142A Upper level, Intu Shopping Centre, Watford, WD17 2UB ",
    phone: "01923 590199",
    lat: 51.654076,
    long: -0.39269,
  },
  {
    id: "2",
    address:
      "MobileBitz Kiosk Adj to the Escalator The Grosvenor Centre Northampton, NN1 2EW ",
    phone: "01604 9476",
    lat: 52.239206,
    long: -0.895702,
  },
  {
    id: "3",
    address:
      "MobileBitz Kiosk Outside Primark Whiterose Shopping Centre Leeds, LS11 8LU ",
    phone: "01133 459495",
    lat: 53.757915,
    long: -1.57417,
  },
  {
    id: "4",
    address: "MobileBitz Unit 61 Whiterose Shopping Centre Leeds, LS11 8LU ",
    phone: "01133 459495",
    lat: 53.758611,
    long: -1.574167,
  },
  {
    id: "5",
    address:
      "MobileBitz Kiosk Outside Body Shop Broadway Shopping Centre Bradford, BD1 1JR ",
    phone: "01274 985745",
    lat: 53.794443,
    long: -1.749847,
  },
  {
    id: "6",
    address: "MobileBitz Kiosk The Mall Shopping Centre Blackburn, BB1 7NQ ",
    phone: "01254 485344",
    lat: 53.748278,
    long: -2.48281,
  },
  {
    id: "7",
    address: "MobileBitz Kiosk C Fishergate Shopping Centre Preston, PR1 8HJ ",
    phone: "01772 305635",
    lat: 53.756994,
    long: -2.705618,
  },
  {
    id: "8",
    address:
      "MobileBitz Kiosk Adj Unit 2.9 Fishergate Shopping Centre Preston, PR1 8HJ ",
    phone: "01772 305635",
    lat: 53.757246,
    long: -2.706132,
  },
  {
    id: "9",
    address: "MobileBitz Kiosk Lakeside Shopping Centre Thurrock, RM20 1WN ",
    phone: "01375 767837",
    lat: 51.487075,
    long: 0.283538,
  },
  {
    id: "10",
    address:
      "MobileBitz Kiosk Outside Pandora Pentagon Shopping Centre Chatham, ME4 4HY ",
    phone: "01634 786068",
    lat: 51.383834,
    long: 0.525398,
  },
  {
    id: "11",
    address:
      "MobiletBitz Kiosk Outside Yankee Candles T2 Bluewater Shopping Centre Kent, DA9 9ST ",
    phone: "01322 839090",
    lat: 51.437931,
    long: 0.272769,
  },
  {
    id: "12",
    address:
      "MobileBitz Kiosk Outside Foot Asylum T9 Bluewater Shopping Centre Kent, DA9 9ST ",
    phone: "01322 839090",
    lat: 51.438234,
    long: 0.270525,
  },
  {
    id: "13",
    address:
      "MobileBitz Kiosk Outside Costa Westfield Stratford Shopping Centre London, E20 1EJ ",
    phone: "02039 526036",
    lat: 51.544035,
    long: -0.005309,
  },
  {
    id: "14",
    address:
      "MobileBitz Kiosk Outside Superdry Ground floor Westfield Stratford Shopping Centre London, E20 1EJ ",
    phone: "02039 526036",
    lat:  51.544035,
    long: -0.005309,
  },
  {
    id: "15",
    address: "MobileBitz Kiosk Inside Asda Beckton Hackney London, E6 5JP ",
    phone: "02031 054453",
    lat: 51.514727,
    long: 0.057999,
  },
  {
    id: "16",
    address:
      "MobileBitz Next to Wilko Meadows Shopping Centre Chelmsford Essex, CM2 6FD ",
    phone: "01245 698716",
    lat: 51.731723,
    long: 0.47611,
  },
  {
    id: "17",
    address:
      "MobileBitz Kiosk Outside Next The Liberty Shopping Centre Romford, RM1 3RL ",
    phone: "01708 912297",
    lat: 51.578677,
    long: 0.184286,
  },
  {
    id: "18",
    address: "MobileBitz Kiosk Outside VodaFone Castle Mall Norwich, NR1 3DD ",
    phone: "01603 927707",
    lat: 52.627396,
    long: 1.296369,
  },
  {
    id: "19",
    address:
      "MobileBitz Kiosk Outside Boots Intu Chappelfield Norwich, NR1 3SH ",
    phone: "01603 927707",
    lat: 52.625721,
    long: 1.290077,
  },
  {
    id: "20",
    address:
      "MobileBitz Kiosk Opposite Boots Grafton Shopping Centre Cambridge, CB1 1PS ",
    phone: "01223 660650",
    lat: 52.206746,
    long: 0.133195,
  },
  {
    id: "21",
    address:
      "MobileBitz Kiosk Outside New Look Queensgate Shopping Centre Peterborough, PE1 1NT ",
    phone: "01733 512200",
    lat: 52.573332,
    long: -0.24461,
  },
  {
    id: "22",
    address:
      "MobileBitz Kiosk The Mall Shopping Centre Walthamstow London, E17 7JR ",
    phone: "02034 419698",
    lat: 51.583406,
    long: -0.023476,
  },
  {
    id: "23",
    address:
      "MobileBitz Kiosk Howard Shopping Centre Welvyn Garden City Hertfordshire, AL8 6HA ",
    phone: "01707 708087",
    lat: 51.80134,
    long: -0.204402,
  },
  {
    id: "24",
    address:
      "MobileBitz Kiosk Outside W H Smith The Mall Shopping Centre, Luton Bedfordshire, LU1 2TL ",
    phone: "01582 967720",
    lat: 51.879875,
    long: -0.411268,
  },
  {
    id: "25",
    address:
      "MobileBitz Kiosk Outside Schuh St Anns Shopping Centre, Harrow London, HA1 1AT ",
    phone: "02030 929343",
    lat: 51.580934,
    long: -0.336383,
  },
  {
    id: "26",
    address:
      "MobileBitz Kiosk Outside Vision Express St Georges Shopping Centre, Harrow London, HA1 1HS ",
    phone: "02030 929343",
    lat: 51.581453,
    long: -0.339434,
  },
  {
    id: "27",
    address:
      "MobileBitz Kiosk Outside Entertainer Intu Shopping Centre Watford, WD17 2TF ",
    phone: "01923 590199",
    lat: 51.654076,
    long: -0.39269,
  },
  {
    id: "28",
    address:
      "MobileBitz Kiosk Outside Fenwicks Brent Cross Shopping Centre London, NW4 3FP ",
    phone: "02038 027964",
    lat: 51.576838,
    long: -0.222453,
  },
  {
    id: "29",
    address:
      "MobileBitz Kiosk Outside Vision Express Elmsleigh Shopping Centre Staines Upon Thames, TW18 4QB ",
    phone: "01784 558323",
    lat: 51.434019,
    long: -0.510155,
  },
  {
    id: "30",
    address:
      "MobileBitz Kiosk Outside Metro Bank Pavillions Shopping Centre Uxbridge, UB8 1LN ",
    phone: "01895 713075",
    lat: 51.546218,
    long: -0.480144,
  },
  {
    id: "31",
    address:
      "MobileBitz Kiosk Outside Robert Dyas The Broadway Shopping Centre Ealing, W5 5JY ",
    phone: "02034 890284",
    lat: 51.512981,
    long: -0.303368,
  },
  {
    id: "32",
    address:
      "MobileBitz Kiosk Outside Burton Intu Shopping Centre Uxbridge, UB8 1LA ",
    phone: "01895 713075",
    lat: 51.545145,
    long: -0.47601,
  },
  {
    id: "33",
    address:
      "MobileBitz Kiosk Broadway Shopping Centre Hammersmith London, W6 9YE ",
    phone: "02039 529027",
    lat: 51.492158,
    long: -0.223953,
  },
  {
    id: "34",
    address:
      "MobileBitz Kiosk Outside Clintons Eden Shopping Centre High Wycombe, HP11 2DQ ",
    phone: "01494 257774",
    lat: 51.629348,
    long: -0.754457,
  },
  {
    id: "35",
    address:
      "MobileBitz Kiosk Outside Accessorize Royal Victoria Place Tunbridge Wells, TN1 2SS ",
    phone: "01732 667647",
    lat: 51.134956,
    long: 0.265892,
  },
  {
    id: "36",
    address:
      "MobileBitz Kiosk Outside Vision Express County Mall, Crawley Essex, RH10 1FG ",
    phone: "01293 279323",
    lat: 51.113358,
    long: -0.185038,
  },
  {
    id: "37",
    address:
      "MobileBitz Kiosk Outside Superdrug Whitgib Shopping Centre Croydon, CR0 1LP ",
    phone: "02038 021791",
    lat: 51.375735,
    long: -0.099952,
  },
  {
    id: "38",
    address:
      "MobileBitz Kiosk Ourtside Natwest Centrale Shopping Centre Croydon, CR0 1TY ",
    phone: "02038 021791",
    lat: 51.375514,
    long: -0.103255,
  },
  {
    id: "39",
    address: "MobileBitz Kiosk Outside M&S Ashley Centre Epsom, KT18 5AB ",
    phone: "01372 879797",
    lat: 51.332484,
    long: -0.268779,
  },
  {
    id: "40",
    address:
      "MobileBitz Kiosk The Glades Shopping Centre Bromley Kent, BR1 1DN ",
    phone: "02038 021823",
    lat: 51.404086,
    long: 0.016766,
  },
  {
    id: "41",
    address:
      "MobileBitz Kiosk, Churchill Square Shopping Centre Brighton BN1 2RG ",
    phone: "01273 973953",
    lat: 50.822674,
    long: -0.145846,
  },
  {
    id: "42",
    address:
      "MobileBitz Kiosk Centre Court Shopping Centre Wimbledon London, SW19 8YA ",
    phone: "02033 057139",
    lat: 51.421508,
    long: -0.204531,
  },
  {
    id: "43",
    address:
      "MobileBitz Kiosk Opposite HMV Festival Place Shopping Centre Basingstoke, RG21 7LJ ",
    phone: "01256 541001",
    lat: 51.264828,
    long: -1.088365,
  },
  {
    id: "44",
    address:
      "MobileBitz Kiosk Outside EE & 3 Store Westquay Shopping Centre Southampton, SO17 1XN ",
    phone: "01381 849772",
    lat: 50.904171,
    long: -1.407556,
  },
  {
    id: "45",
    address:
      "MobileBitz Kiosk Friary Shopping Centre, Guildford Surrey, GU1 4YT ",
    phone: "01483 385252",
    lat: 51.237154,
    long: -0.576298,
  },
  {
    id: "46",
    address:
      "MobileBitz Kiosk Outside Next The Oracle Shopping Centre Reading, RG1 2AG ",
    phone: "01183 274688",
    lat: 51.454637,
    long: -0.970776,
  },
  {
    id: "47",
    address:
      "MobileBitz Kiosk The Lexicon Princess Sqaure Bracknell Berkshire, RG12 1LS ",
    phone: "01344 249292",
    lat: 51.4152,
    long: -0.750939,
  },
  {
    id: "48",
    address:
      "MobileBitz Kiosk Outside Sainsburys The Sqaure Shopping Centre Camberley, Surrey, GU15 3SL ",
    phone: "01276 423652",
    lat: 51.33801,
    long: -0.746188,
  },
  {
    id: "49",
    address:
      "MobileBitz Kiosk Outside Lush Drake Circus Shopping Centre Plymouth, PL1 1EA ",
    phone: "01752 946706",
    lat: 50.372368,
    long: -4.138554,
  },
  {
    id: "50",
    address:
      "MobileBitz Kiosk Outside Currys PC World St Davids 2, St Davids Centre Cardiff, CF10 2ER ",
    phone: "02921 151051",
    lat: 51.479533,
    long: -3.175496,
  },
  {
    id: "51",
    address:
      "MobileBitz Kiosk Outside H&M Intu MK Shopping Centre Milton Keynes, MK9 3GB ",
    phone: "01908 477593",
    lat: 52.043777,
    long: -0.753795,
  },
  {
    id: "52",
    address:
      "MobileBitz Kiosk Outside Superdrug Castle Quay Shopping Centre Banbury, OX16 5UN ",
    phone: "01295 367137",
    lat: 52.063429,
    long: -1.334969,
  },
];

export default AddressData;
