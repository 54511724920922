import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Select from "react-select";

import { timeslotOptions } from "../../docs/TimeData";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Appointment = ({ data, setData, navigation }) => {
  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");

  const bookAppointment = (e) => {
    e.preventDefault();

    let error = false;
    var phonecheckReg = new RegExp(
      "^[+]?[0-9]{2}?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}$"
    );
    var emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (startDate === "") {
      error = true;
      document.getElementById("appointment-date").style.borderBottom =
        "1px solid red";
    } else {
      error = false;
      document.getElementById("appointment-date").style.borderBottom = "none";
    }
    if (time === "") {
      error = true;
      document.getElementsByClassName("css-1hwfws3")[0].style.borderBottom =
        "1px solid red";
      document.getElementsByClassName("css-1wy0on6")[0].style.borderBottom =
        "1px solid red";
    } else {
      error = false;
      document.getElementsByClassName("css-1hwfws3")[0].style.borderBottom =
        "none";
      document.getElementsByClassName("css-1wy0on6")[0].style.borderBottom =
        "none";
    }
    if (name === "") {
      error = true;
      document.getElementById("fname").style.borderBottom = "1px solid red";
    } else {
      error = false;
      document.getElementById("fname").style.borderBottom = "none";
    }
    if (email === "") {
      error = true;
      document.getElementById("email").style.borderBottom = "1px solid red";
    } else {
      error = false;
      document.getElementById("email").style.borderBottom = "none";
    }
    if (phone === "" || phone.length !== 13 || !phonecheckReg.test(phone)) {
      error = true;
      document.getElementById("phone").style.borderBottom = "1px solid red";
    } else {
      error = false;
      document.getElementById("phone").style.borderBottom = "none";
    }

    if (!error) {

      var date =
        new Date(startDate).getDate().toString() +
        "/" +
        new Date(startDate).getMonth().toString() +
        "/" +
        new Date(startDate).getFullYear().toString();

      let appointmentobject = {
        name: name,
        time: time,
        email: email,
        phone: phone,
        notes: notes,
        date: date,
        appointmentNumber: Math.floor(10000000 + Math.random() * 90000000),
      };

      setData({
        ...data,
        appointmentdetails: appointmentobject,
      });
      navigation.next();
    }
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const handleChange = (newValue, actionMeta) => {
    console.log(newValue);
    setTime(newValue.value);
  };
  const handleInputChange = (inputValue, actionMeta) => {
  };

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <h3>Finalise your appointment</h3>
        <div style={{ marginTop: "1rem" }} className="appointment-div">
          <form>
            <div className="input-group mb-3">
              <label htmlFor="fname">Date</label>
              <div id="appointment-date" className="appointment-date">
                <DatePicker
                  selected={startDate}
                  minDate={new Date()}
                  filterDate={(date) =>
                    date.getDay() !== 0 && date.getDay() !== 6
                  }
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                />
              </div>
            </div>

            <div className="input-group mb-3">
              <label htmlFor="fname">Timeslot</label>
              <Select
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={timeslotOptions}
                formatGroupLabel={formatGroupLabel}
              />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="fname">Full Name</label>
              <input
                type="text"
                id="fname"
                className="appointment-input"
                name="firstname"
                placeholder="Your name.."
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="appointment-input"
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-group mb-3">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="appointment-input"
                defaultValue="+44"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <textarea
                name="notes"
                className="form-control"
                id="notes"
                rows="3"
                style={{ backgroundColor: "#fcfcfc" }}
                placeholder="Please add any additional points here"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
            <p>
              The estimated cost for {data.service} of {data.device}{" "}
              {data.model} is &#163;{data.price}
            </p>
            <div
            style={{
              height: "75px",
              width: "100%",
              paddingBottom: '15px',
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'center',
              
            }}
          >
            <input
              className="appointment-submit"
              type="submit"
              value="Book Appointment"
              onClick={bookAppointment}
              style={{
                bottom: "10px",
                position: "fixed",
                marginBottom: "0px",
                borderRadius: "0px",
                width: '95%'
              }}
            />
          </div>
            
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Appointment;
