import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
// import back from "../../assets/img/back.png";
import {
  phoneName,
  iphoneService,
  samsungService,
  ipadService,
  appleIphone,
  appleIpad,
  samsung,
} from "../../docs/data";

let serviceData = [];
let modelData = [];

const ChooseDevice = ({ data, setData, navigation }) => {
  const setDevice = (device) => {
    if (device === "Apple iPhone") {
      serviceData = iphoneService;
      modelData = appleIphone;
    }
    if (device === "Apple iPad") {
      serviceData = ipadService;
      modelData = appleIpad;
    }
    if (device === "Samsung") {
      serviceData = samsungService;
      modelData = samsung;
    }

    setData({
      ...data,
      device: device,
      serviceData: serviceData,
      modelData: modelData,
    });
    navigation.next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <h3
          style={{
            marginBottom: "1.5rem",
          }}
        >
          Choose your device
        </h3>
        {phoneName.map((device, index) => (
          <Button
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              border: "1px solid black",
              margin: "0.5rem 0",
            }}
            variant="flat"
            size="lg"
            block
            name={device}
            value={device}
            onClick={() => setDevice(device)}
          >
            {device}
          </Button>
        ))}
        {/* <img src={back}></img>Back */}
      </Container>
      <div
        style={{
          fontSize: "17px",
          fontWeight: "500",
          position: "fixed",
          width: "100%",
          bottom: "3px",
          background: "#fff",
          paddingTop: "1rem",
          boxShadow: "0 -5px 5px -5px #333",
          paddingLeft: "15px",
        }}
      >
        <p style={{
              width: "19%",
            }} onClick={() => navigation.previous()}>
          <b>&lt; Back</b>
        </p>
      </div>
    </div>
  );
};

export default ChooseDevice;
