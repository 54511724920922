import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
// import back from "../../assets/img/back.png";
import { serviceCost } from "../../docs/data";

const ChooseService = ({ data, setData, navigation }) => {
  const setService = (service, price) => {
    setData({
      ...data,
      service: service,
      price: price,
    });
    // POST request using fetch()
    navigation.next();
  };


  const [servicedataobject, setServicedataobject] = useState({})


  useEffect(() => {
    window.scrollTo(0, 0);
    /* console.log(m1);
    console.log(serviceCost); */
    /* var newserviceData = data.serviceData.map((service) => {
      m2 = service.replaceAll(" ", "").toLowerCase().toString();
      console.log(m2);
      console.log(serviceCost.appleiphone['sixs'][m2]);
      console.log(serviceCost.appleiphone.sixs[m2]);
      return {
        batteryrepair: serviceCost.appleiphone.sixs[m2],
        chargingportrepair: serviceCost.appleiphone.sixs[m2],
        frontcamerarepair: serviceCost.appleiphone.sixs[m2],
        backcamerarepair: serviceCost.appleiphone.sixs[m2],
        screenrepair: serviceCost.appleiphone.sixs[m2],
        generaldiagnostics: serviceCost.appleiphone.sixs[m2],
      };
    }); */

    let device = data.device.replaceAll(" ", "").toLowerCase().toString();

    var newserviceData = {};

    data.serviceData.forEach((service) => {
      /* let m2 = service.replaceAll(" ", "").toLowerCase().toString(); */
      // let model = "$" + data.model;
      let model = "$" + data.model.replaceAll(" ", "").toLowerCase().toString();
      console.log(device, model);
      newserviceData[service] = serviceCost[device][model][service];
    });

    /* const filterArray = newserviceData.map((service) => {
      if (newserviceData[service] !== "NA") delete newserviceData[service];
    }); */

    /* const filterArray = newserviceData.forEach((service) => {
      if (newserviceData[service] !== "NA") delete newserviceData[service];
    }); */

    for (const property in newserviceData) {
      if (newserviceData[property] === "NA") delete newserviceData[property];
    }

    /* console.log(filterArray); */

    /* var newserviceData = data.serviceData.map((service) => {
      return {
        batteryrepair: serviceCost[h].sixs[m2],
        chargingportrepair: serviceCost[h].sixs[m2],
        frontcamerarepair: serviceCost[h].sixs[m2],
        backcamerarepair: serviceCost[h].sixs[m2],
        screenrepair: serviceCost[h].sixs[m2],
        generaldiagnostics: serviceCost[h].sixs[m2],
      };
    }); */

    setServicedataobject(newserviceData);
  }, []);

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <h3
        style={{
          marginBottom: '1.5rem'
        }}>Choose your service</h3>
        {Object.keys(servicedataobject).map((key, index) => (
          <Button
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid black",
              paddingTop: "16px",
              margin: "0.5rem 0",
              height: "60px"
            }}
            size="lg"
            variant="flat"
            block
            name={key}
            value={key}
            onClick={() => setService(key, servicedataobject[key])}
          >
            <p>{key}</p>
            <p><b>&#163; {servicedataobject[key]}</b></p>
          </Button>
        ))}

        {/* <Button
          style={{
            display: "flex",
            justifyContent: "center",
            border: "1px solid black",
            background: "black",
            color: "#fff",
            margin: "2rem 0",
          }}
          size="lg"
          block
          onClick={onNext}
        >
          Next
        </Button> */}
        {/* <img src={back}></img>Back */}
      </Container>
      <div
        style={{
          fontSize: "17px",
          fontWeight: "500",
          position: "fixed",
          width: "100%",
          bottom: "3px",
          background: "#fff",
          paddingTop: "1rem",
          boxShadow: "0 -5px 5px -5px #333",
          paddingLeft: "15px"
          
        }}
      >
        <p  style={{
              width: "19%",
            }} onClick={() => navigation.previous()}><b>&lt; Back</b></p>
      </div>
    </div>
  );
};

export default ChooseService;
