import React, { Component } from 'react'

export default class FinalScreen extends Component {
    render() {
        return (
            <div className="multi-repair-form d-flex">
                <h1 style={{
                    position: 'center',
                    margin: '10rem 2rem'

                }}>Your appointment has been booked. You may close this window.</h1>
            </div>
        )
    }
}
