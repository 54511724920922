import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";

import AddressData from "../../docs/AddressData";
import Map from "../Map/Map";

const ChooseAddress = ({ formData, setForm, data, setData, navigation }) => {
  const [mapValue, setMapValue] = useState({
    lat: AddressData[0].lat,
    lng: AddressData[0].long,
  });

  const setAddress = (address, phone, lat, long) => {
    setData({
      ...data,
      address: address,
      locationContactNumber: phone,
    });

    setMapValue({
      lat: lat,
      lng: long,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  function searchAddress() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("address-search-input");
    filter = input.value.toUpperCase();
    ul = document.getElementById("address-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("button")[0];
      txtValue = a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  function onNext() {
    if (data.address !== "") {
      navigation.next();
    }
  }

  return (
    <>
      <div className="multi-repair-form d-flex ">
        <Container>
          <h3
            style={{
              marginBottom: "1.5rem",
            }}
          >
            Find your nearest branch
          </h3>

          <input
            type="text"
            id="address-search-input"
            onKeyUp={searchAddress}
            placeholder="Search"
            title="Type in an address"
          />
          <div className="container">
            <Map mapValue={mapValue} />
          </div>
          <ul id="address-list">
            {AddressData.map((address, id) => (
              <li key={id}>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid black",
                    margin: "0.5rem 0",
                  }}
                  variant="flat"
                  size="lg"
                  block
                  name={address.address}
                  value={address.address}
                  onClick={() =>
                    setAddress(
                      address.address,
                      address.phone,
                      address.lat,
                      address.long
                    )
                  }
                >
                  {address.address} {address.phone}
                </Button>
              </li>
            ))}
          </ul>
          {/* <img src={back}></img>Back */}
        </Container>
        <div
          style={{
            fontSize: "17px",
            fontWeight: "500",
            position: "fixed",
            width: "100%",
            bottom: "3px",
            background: "#fff",
            paddingTop: "1rem",
            boxShadow: "0 -5px 5px -5px #333",
            paddingLeft: "15px",
          }}
        >
          <p
            style={{
              top: "17px",
              width: "19%",
            }}
            onClick={() => navigation.previous()}
          >
            <b>&lt; Back</b>
          </p>
          <p
            style={{
              position: "absolute",
              bottom: "10px",
              width: "15%",
              right: "18px",
              top: "17px",
            }}
            onClick={onNext}
          >
            <b>Next &gt;</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default ChooseAddress;
