import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";



const Map = ({ mapValue }) => {
  function createMapOptions(maps) {
    return {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    }
  }
  return (
    <div style={{ height: "200px", width: "100%" }}>
      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={{ key: "AIzaSyCL877KYGdr5oIZ-1fM8gcaBtQZWzcC4ig" }}
        center={mapValue}
        defaultZoom={15}
      >
        <Marker lat={mapValue.lat} lng={mapValue.lng} text={""} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
