import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
// import back from "../../assets/img/back.png";
const ChooseModel = ({ data, setData, navigation }) => {
  const setModel = (model) => {
    setData({
      ...data,
      model: model,
    });
    navigation.next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <h3
          style={{
            marginBottom: "1.5rem",
          }}
        >
          Choose your model
        </h3>
        {data.modelData.map((model, index) => (
          <Button
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              border: "1px solid black",
              margin: "0.5rem 0",
            }}
            variant="flat"
            size="lg"
            block
            name={model}
            value={model}
            onClick={() => setModel(model)}
          >
            {model}
          </Button>
        ))}
        {/* <img src={back}></img>Back */}
        {/* <Button onClick={navigation.previous()}>Back</Button> */}
      </Container>
      <div
        style={{
          fontSize: "17px",
          fontWeight: "500",
          position: "fixed",
          width: "100%",
          bottom: "3px",
          background: "#fff",
          paddingTop: "1rem",
          boxShadow: "0 -5px 5px -5px #333",
          paddingLeft: "15px",
        }}
      >
        <p style={{
              width: "19%",
            }} onClick={() => navigation.previous()}>
          <b>&lt; Back</b>
        </p>
      </div>
    </div>
  );
};

export default ChooseModel;
