export const phoneName = ["Apple iPhone", "Apple iPad", "Samsung"];

export const serviceCost = {
  appleiphone: {
    $6s: {
      "Battery Repair": 40,
      "Charging Port Repair": 35,
      "Front Camera Repair": 30,
      "Back Camera Repair": 40,
      "Screen Repair": 55,
      "Speaker Repair": 0,
      "General Diagnostics": "NA",
    },
    $6splus: {
      "Battery Repair": 40,
      "Charging Port Repair": 40,
      "Front Camera Repair": 30,
      "Back Camera Repair": 40,
      "Screen Repair": 40,
      "Speaker Repair": 65,
      "General Diagnostics": 0,
    },
    $7: {
      "Battery Repair": 40,
      "Charging Port Repair": 70,
      "Front Camera Repair": 40,
      "Back Camera Repair": 45,
      "Screen Repair": 70,
      "Speaker Repair": 45,
      "General Diagnostics": 0,
    },
    $7plus: {
      "Battery Repair": 40,
      "Charging Port Repair": 50,
      "Front Camera Repair": 45,
      "Back Camera Repair": 100,
      "Screen Repair": 80,
      "Speaker Repair": 45,
      "General Diagnostics": 0,
    },
    $8: {
      "Battery Repair": 45,
      "Charging Port Repair": 50,
      "Front Camera Repair": 50,
      "Back Camera Repair": 100,
      "Screen Repair": 80,
      "Speaker Repair": 50,
      "General Diagnostics": 0,
    },
    $8plus: {
      "Battery Repair": 45,
      "Charging Port Repair": 50,
      "Front Camera Repair": 55,
      "Back Camera Repair": 100,
      "Screen Repair": 90,
      "Speaker Repair": 50,
      "General Diagnostics": 0,
    },
    "$se(2020)": {
      "Battery Repair": 45,
      "Charging Port Repair": 50,
      "Front Camera Repair": 50,
      "Back Camera Repair": 100,
      "Screen Repair": 80,
      "Speaker Repair": 50,
      "General Diagnostics": 0,
    },
    $x: {
      "Battery Repair": 45,
      "Charging Port Repair": 50,
      "Front Camera Repair": 50,
      "Back Camera Repair": 90,
      "Screen Repair": 120,
      "Speaker Repair": 55,
      "General Diagnostics": 0,
    },
    $xr: {
      "Battery Repair": 45,
      "Charging Port Repair": 65,
      "Front Camera Repair": 60,
      "Back Camera Repair": 120,
      "Screen Repair": 130,
      "Speaker Repair": 60,
      "General Diagnostics": 0,
    },
    $xs: {
      "Battery Repair": 60,
      "Charging Port Repair": 65,
      "Front Camera Repair": 75,
      "Back Camera Repair": 120,
      "Speaker Repair": 60,
      "Screen Repair": 180,
      "General Diagnostics": 0,
    },
    $xsmax: {
      "Battery Repair": 70,
      "Charging Port Repair": 65,
      "Front Camera Repair": 75,
      "Back Camera Repair": 120,
      "Speaker Repair": 60,
      "Screen Repair": 210,
      "General Diagnostics": 0,
    },
    $11: {
      "Battery Repair": 70,
      "Charging Port Repair": 70,
      "Front Camera Repair": 110,
      "Back Camera Repair": 120,
      "Speaker Repair": 80,
      "Screen Repair": 180,
      "General Diagnostics": 0,
    },
    $11pro: {
      "Battery Repair": 80,
      "Charging Port Repair": 80,
      "Front Camera Repair": 120,
      "Back Camera Repair": 130,
      "Speaker Repair": 80,
      "Screen Repair": 240,
      "General Diagnostics": 0,
    },
    $11promax: {
      "Battery Repair": 80,
      "Charging Port Repair": 80,
      "Front Camera Repair": 110,
      "Back Camera Repair": 130,
      "Speaker Repair": 80,
      "Screen Repair": 280,
      "General Diagnostics": 0,
    },
  },
  samsung: {
    "$s7": {
      "Battery Repair": 55,
      "Screen Repair": 130,
      "General Diagnostics": 0,
    },
    "$s7edge": {
      "Battery Repair": 55,
      "Screen Repair": 190,
      "General Diagnostics": 0,
    },
    "$s8": {
      "Battery Repair": 55,
      "Screen Repair": 220,
      "General Diagnostics": 0,
    },
    "$s8+": {
      "Battery Repair": 55,
      "Screen Repair": 230,
      "General Diagnostics": 0,
    },
    "$s9": {
      "Battery Repair": 55,
      "Screen Repair": 230,
      "General Diagnostics": 0,
    },
    "$s9+": {
      "Battery Repair": 60,
      "Screen Repair": 240,
      "General Diagnostics": 0,
    },
    "$s10": {
      "Battery Repair": 60,
      "Screen Repair": 230,
      "General Diagnostics": 0,
    },
    "$s10+": {
      "Battery Repair": 60,
      "Screen Repair": 275,
      "General Diagnostics": 0,
    },
    "$s105g": {
      "Battery Repair": 70,
      "Screen Repair": 275,
      "General Diagnostics": 0,
    },
    "$note8": {
      "Battery Repair": 55,
      "Screen Repair": 230,
      "General Diagnostics": 0,
    },
    "$note9": {
      "Battery Repair": 55,
      "Screen Repair": 250,
      "General Diagnostics": 0,
    },
    "$note10": {
      "Battery Repair": 60,
      "Screen Repair": 240,
      "General Diagnostics": 0,
    },
    "$note10+": {
      "Battery Repair": 60,
      "Screen Repair": 260,
      "General Diagnostics": 0,
    },
    "$note10+5g": {
      "Battery Repair": 100,
      "Screen Repair": 260,
      "General Diagnostics": 0,
    },
  },
  appleipad: {
    "$allmodels": {
      "Screen Repair": 50,
      "Speaker Repair": 50,
    },
  },
};

export const iphoneService = [
  "Battery Repair",
  "Charging Port Repair",
  "Front Camera Repair",
  "Back Camera Repair",
  "Screen Repair",
  "Speaker Repair",
  "General Diagnostics",
];

export const samsungService = [
  "Battery Repair",
  "Screen Repair",
  "General Diagnostics",
];

export const ipadService = ["Screen Repair", "Speaker Repair"];

export const appleIphone = [
  "6s",
  "6s Plus",
  "7",
  "7 Plus",
  "8",
  "8 Plus",
  "SE(2020)",
  "X",
  "XR",
  "XS",
  "XS Max",
  "11",
  "11 Pro",
  "11 Pro Max",
];

export const appleIpad = ["All Models"];

export const samsung = [
  "S7",
  "S7 Edge",
  "S8",
  "S8+",
  "S9",
  "S9+",
  "S10",
  "S10+",
  "S10 5G",
  "Note 8",
  "Note 9",
  "Note 10",
  "Note 10+",
  "Note 10+ 5G",
];