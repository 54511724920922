import React from 'react';
import MarkerIcon from './ic_marker.svg';

const Marker = () => {
    return (
      <div className="marker">
          <img src={MarkerIcon} alt="marker"></img>
       </div>
    );
  };

  export default Marker;