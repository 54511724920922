import React from "react";
import { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import greentick from "../../assets/img/green-tick.svg";
const axios = require("axios");
;

const Booked = ({ data, setData, navigation }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
    var postData = {
      appointmentdetails: data.appointmentdetails,
      model: data.model,
      device: data.device,
      service: data.service,
      price: "£" + data.price,
      location: "MobileBlitz",
      locationContactNumber: data.phone,
      address: data.address,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(
        "https://repairs.vendiapp.com/api/v1/appointments",
        postData,
        axiosConfig
      )
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  }, [data]);

  const redirectPage = () => {
    window.location.href = "https://vendiapp.com/appointment-booked";
  };

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <div
          style={{
            display: "flex",
            margin: "2rem 0",
            justifyContent: "center",
          }}
        >
          <img src={greentick} alt="green tick" />
        </div>
        <p className="text-center">
          <h3>Appointment booked successfully</h3>
        </p>
        {/* Appointment Details */}
        <div className="booking-detials">
          <div>
            <p className="text-muted">Device</p>
            <p>
              {data.device} {data.model}
            </p>
          </div>
          <div>
            <p className="text-muted">
              Appointment <br /> Number
            </p>
            <br />
            <p>#{data.appointmentdetails.appointmentNumber}</p>
          </div>
          <div>
            <p className="text-muted">Date</p>
            <p>{data.appointmentdetails.date}</p>
          </div>
          <div>
            <p className="text-muted">Timeslot</p>
            <p>{data.appointmentdetails.time}</p>
          </div>
          <div>
            <p className="text-muted">Location</p>
            <div style={{ width: "192px" }}>
              <p>{data.address}</p>
            </div>
          </div>
          <div>
            <p className="text-muted">Name</p>
            <p>{data.appointmentdetails.name}</p>
          </div>
          <div>
            <p className="text-muted">Email</p>
            <p>{data.appointmentdetails.email}</p>
          </div>
          <div>
            <p className="text-muted">Phone</p>
            <p>{data.appointmentdetails.phone}</p>
          </div>
        </div>
        {/* <Link to="https://vendiapp.com/appointment-booked"> */}
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            border: "1px solid black",
            background: "black",
            color: "#fff",
            margin: "2rem 0",
          }}
          size="lg"
          block
          onClick={redirectPage}
        >
          Done
        </Button>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default Booked;
