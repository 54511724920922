import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import logo from "../../assets/img/MB_Logo_2020.png";
import one from "../../assets/img/1.png";
import two from "../../assets/img/2.png";
import three from "../../assets/img/3.png";

const StartScreen = ({ navigation }) => {
  const [height, setHeight] = useState();
  const [imgwidth, setImgwidth] = useState();
  const onNext = () => {
    navigation.next();
  };

  useEffect(() => {
    var height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    height = height - 100;
    height = height + "px";
    setHeight(height);
    console.log(height);
    /* height = height - 100;
    height = height + "px";
    setImgwidth(height);
    console.log(imgwi) */

    var wind =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    console.log(wind);
    wind = wind - 200;
    wind = wind + "px";

    setImgwidth(wind);
    console.log(imgwidth);
  }, []);

  return (
    <div className="multi-repair-form d-flex ">
      <Container>
        <div style={{ height: '90vh', 
                      display:'flex',
                      'flexDirection': 'column', 
                      overflowY: 'hidden'}}>
          <br /><h3 >Book a repair with MobileBitz</h3> 
          <div style={{
            width: '100%',
            flexGrow: '1',
            overflowY: 'auto'
          }}>
            <p>We offer repair services for your device through our trusted repair partner, Mobile Bitz</p><br />
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <img
                style={{ width: "300px", height: "93px", maxWidth: '400px' }}
                src={logo}
                alt="start screen"
              />
            </div>
          <br />
          <p>MobileBitz is one of the UK's most trusted repair brands with over 60 locations across the UK</p>
          <br />

          <h4>How it works</h4><br />
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <img src={one} height="30px" width="30px" align="left" ></img>
            <h6 style={{margin:"0px", paddingLeft: '10px'}} align="left">Book your appointment on vendi</h6>
          </div><br />
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <img src={two} height="30px" width="30px" align="left" ></img><h6 style={{margin:"0px", paddingLeft: '10px'}} align="left">Drop off your phone at a Mobile Bitz location</h6><br />
          </div><br />
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <img src={three} height="30px" width="30px" align="left" ></img><h6 style={{margin:"0px", paddingLeft: '10px'}} align="left">Pay after your repair is complete</h6><br />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            {/* <img src={three} height="30px" width="30px" align="left" ></img><h6 style={{margin:"0px", paddingLeft: '10px'}} align="left">Pay after your repair is complete</h6><br /> */}
          </div>
          </div>
          <div
            style={{
              height: "75px",
              width: "90%",
              paddingBottom: '15px',
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '15px',
              marginRight: '15px'
            }}
          >
            <Button
              style={{
                background: "black",
                color: "#fff",
                border: "none",
                position: "fixed",
                borderRadius: '2px',
                bottom: '10px',
                boxShadow: '0 4px 3px -3px black',
                width: '95%'
              }}
              size="lg"
              block
              onClick={onNext}
            >
              Next
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default StartScreen;
