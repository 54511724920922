export const timeslotOptions = [
  { value: "9:00-9:30", label: "9:00-9:30" },
  { value: "10:00-10:30", label: "10:00-10:30" },
  { value: "11:00-11:30", label: "11:00-11:30" },
  { value: "12:00-12:30", label: "12:00-12:30" },
  { value: "13:00-13:30", label: "13:00-13:30" },
  { value: "14:00-14:30", label: "14:00-14:30" },
  { value: "15:00-15:30", label: "15:00-15:30" },
  { value: "16:00-16:30", label: "16:00-16:30" },
  { value: "17:00-17:30", label: "17:00-17:30" },
  { value: "18:00-18:30", label: "18:00-18:30" },
  { value: "19:00-19:30", label: "19:00-19:30" },
  { value: "20:00-20:30", label: "20:00-20:30" },
];
