import React, { useState } from "react";
import { useForm, useStep } from "react-hooks-helper";

import ChooseService from "./stepForm/ChooseService";
import ChooseModel from "./stepForm/ChooseModel";
import ChooseDevice from "./stepForm/ChooseDevice";
import ChooseAddress from "./stepForm/ChooseAddress";
import Appointment from "./stepForm/Appointment";
import Booked from "./stepForm/Booked";
import StartScreen from "./stepForm/StartScreen";
import FinalScreen from "./stepForm/FinalScreen";

let defaultData = {
  fullname: "",
  email: "",
  phone: "",
  date: "",
  timeslot: "",
  notes: "",
};

const steps = [
  { id: "startscreen" },
  { id: "choosedevice" },
  { id: "choosemodel" },
  { id: "chooseservice" },
  { id: "chooseaddress" },
  { id: "appointment" },
  { id: "booked" },
  { id: "finalscreen"}
];

const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const [data, setData] = useState({
    device: "",
    model: "",
    service: {},
    address: "",
    serviceData: [],
    modelData: [],
    appointmentdetails: {},
    price: "",
    locationContactNumber: "",
  });

  console.log(data);

  const props = { formData, setForm, data, setData, navigation };

  switch (step.id) {
    case "startscreen":
      return <StartScreen {...props} />;
    case "choosedevice":
      return <ChooseDevice {...props} />;
    case "choosemodel":
      return <ChooseModel {...props} />;
    case "chooseservice":
      return <ChooseService {...props} />;
    case "chooseaddress":
      return <ChooseAddress {...props} />;
    case "appointment":
      return <Appointment {...props} />;
    case "booked":
      return <Booked {...props} />;
    case "finalscreen":
      return <FinalScreen {...props} />;
  }

  return <StartScreen {...props} />;
};

export default MultiStepForm;
